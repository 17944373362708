.content {
  margin: 0 1em 1em;
  min-height: 600px;
  flex: 1 0 auto;
}

.App {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}


/* stops the scrollbar from being hidden by default */

.content ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.content ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

a {
  color: #5084AC;
}
